exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-content-writing-mdx": () => import("./../../../src/templates/service-details.js?__contentFilePath=/opt/build/repo/src/contents/services/content-writing.mdx" /* webpackChunkName: "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-content-writing-mdx" */),
  "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-digital-marketing-mdx": () => import("./../../../src/templates/service-details.js?__contentFilePath=/opt/build/repo/src/contents/services/digital-marketing.mdx" /* webpackChunkName: "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-digital-marketing-mdx" */),
  "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-graphic-design-mdx": () => import("./../../../src/templates/service-details.js?__contentFilePath=/opt/build/repo/src/contents/services/graphic-design.mdx" /* webpackChunkName: "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-graphic-design-mdx" */),
  "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-mobile-app-mdx": () => import("./../../../src/templates/service-details.js?__contentFilePath=/opt/build/repo/src/contents/services/mobile-app.mdx" /* webpackChunkName: "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-mobile-app-mdx" */),
  "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-search-engine-optimisation-mdx": () => import("./../../../src/templates/service-details.js?__contentFilePath=/opt/build/repo/src/contents/services/search-engine-optimisation.mdx" /* webpackChunkName: "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-search-engine-optimisation-mdx" */),
  "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-web-development-mdx": () => import("./../../../src/templates/service-details.js?__contentFilePath=/opt/build/repo/src/contents/services/web-development.mdx" /* webpackChunkName: "component---src-templates-service-details-js-content-file-path-opt-build-repo-src-contents-services-web-development-mdx" */)
}

